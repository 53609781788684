import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'
import {getImage} from "../helpers";
import {Button, CardImg, Heading, DotContainer, Dot} from "../styles/projectsStyles";
import BackImg from "../images/back.png"
import ContactForm from "../components/contact"

export default class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    }
  }
  
render() {
  return (
      <Layout>
        <Seo
          title="Docks, Barging & Pile Driving Projects"
          description="We are your go-to guys for anything that needs built up, torn down and everything in between. We take pride in being versatile craftsman able to take on any new construction, repair or remodeling jobs for land or marine. Checkout some of our past projects."
          keywords={["Jake On The Lakes", "Pile Driving Services", "New Dock Construction", "Barging Services", "Coeur d’Alene Lake", "Hauser Lake", "Newman Lake", "Priest Lake", "Pend Oreille Lake", "Cocolalla Lake", "Spokane River", "Expertise In The Northwest"]}
        />
        <Container>
            <Title>ALL PROJECTS</Title>
            <DotContainer>
                <Dot/>
                <Dot/>
                <Dot/>
            </DotContainer>
            <ProjectContainer>
                {this.props.data.allNodeProject.edges.map(({ node }) => {
                    let photo = getImage(node);
                    return (
                      <Button  key={node.title} to={`${node.fields.slug}`}>
                          <CardImg src={photo.url} alt={photo.alt}/>
                          <Heading style={{backgroundImage: `url(${BackImg})`, maxWidth: "-webkit-fill-available"}}>{node.title}</Heading>
                      </Button>
                    )
                })}
            </ProjectContainer>
        </Container>
        <ContactForm data={this.props.data} />
      </Layout>
    )
  }
}

export const query = graphql`
    query {
      allNodeProject {
        edges {
          node {
            nid: drupal_internal__nid
            title
            fields {
              slug
            }
            relationships {
              field_featured_image {
                relationships {
                  field_media_image {
                    publicUrl
                  }
                }
              }
            }
          }
        }
      }
      webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
        drupal_internal__id
        elements {
            name
            type
            attributes {
              name
              value
            }
        }
      }
    }
    `
    
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  color: #707070;
`;

const Title = styled.h1`
  font-size: 45px;
  text-align: center;
  margin-top: 150px;
  letter-spacing: 16.5px;
  line-height: normal;
  font-weight: normal;
`;



const ProjectContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 90%;
    font-family: 'Raleway', sans-serif;
    color: #707070;
    min-height: 100vh;
`;
