export const getImage = (item, index = 0) =>{
  let image = {};

  if (item.relationships && item.relationships.field_featured_image) {
    if (item.relationships.field_featured_image) {
      if (item.relationships.field_featured_image.field_media_image) {
        image = item.relationships.field_featured_image.field_media_image;
      }

      if (item.relationships.field_featured_image) {
        image.url = item.relationships.field_featured_image.relationships.field_media_image.publicUrl;
      }
    }
  }

  if (!image.url && item.relationships.field_image) {
    if (item.relationships.field_image.field_media_image) {
      image = item.relationships.field_image.field_media_image;
    }

    image.url = item.relationships.field_image.relationships.field_media_image.publicUrl;
  }

  if (!image.url && item.relationships.field_category) {
    if (item.relationships.field_category.relationships.field_image) {
      image = item.relationships.field_category.relationships.field_image.field_media_image;
    }

    image.url = item.relationships.field_category.relationships.field_image.relationships.field_media_image.publicUrl;
  }

  if (item.name) {
    if (!image.alt) {
      image.alt = item.name;
    }
    if (!image.title) {
      image.title = item.name;
    }
  }

  return image;
}

export const processImage = (node, field, media = 'field_media_image') => {
  let image = {};

  if (node.relationships && field && node.relationships[field]) {
    image = {...node.relationships[field].field_media_image, ...node.relationships[field].relationships[media].localFile.childImageSharp}
  } else if (node.field_media_image) {
    image = {...node.field_media_image, ...node.relationships.field_media_image.localFile.childImageSharp}
  }

  return image;
};
