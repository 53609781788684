import styled from "styled-components";
import { Link } from "gatsby"

const Button = styled(Link)`
    display: flex;
    flex-direction: column;
    height: 30vh;
    width: 32%;
    border: solid #707070;
    border-width: thin;
    background-color: #EEE;
    color: #707070;
    text-decoration: none;
    margin-bottom: 20px;
    @media (max-width: 450px) {
        width: 90%;
    }
`;

const CardImg = styled.img`
    height: 67%;
    margin: 0px;
    object-fit: cover;
    background-position-y: center;
`;


const Heading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 28px;
    letter-spacing: 4.1px;
    max-width: 70%;
    align-self: center;
    text-align: center;
    align-items: center;
    height: 33%;
    background-size: cover;
    width: -webkit-fill-available;
`;

const Dot = styled.div`
  height: 8px;
  width: 8px;
  background-color: #fff;
  border: #707070 solid thin;
  border-radius: 50%;
  margin: 20px;
`;

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;
`;

export {
    CardImg,
    Heading,
    Button,
    DotContainer,
    Dot,
}